<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="title">
          {{ $tc('title.ajuste_verba_reducao_massiva', 1) }}
        </div>
      </v-card-title>

      <v-form ref="form"
        v-model="formValido"
        lazy-validation
        autocomplete="off">
        <ajuste-verba-reducao-massiva-form-resumo
          ref="resumo"
          v-if="novo || ajusteCarregado"
          :somente-leitura="somenteLeitura"
          :ajuste-verba="ajusteVerba"
          :edicao="!novo"
          @AjusteVerbaReducaoMassivaFormResumo__AplicaPeriodo="aplicarPeriodo"
          @AjusteVerbaReducaoMassivaFormResumo__OrcamentoSelecionado="orcamentoAlterado">
        </ajuste-verba-reducao-massiva-form-resumo>

        <ajuste-verba-reducao-massiva-form-linhas
          v-if="exibeSpreadsheet"
          ref="linhas"
          :somente-leitura="somenteLeitura"
          :edicao="!novo"
          :ajuste-verba="ajusteVerba">
        </ajuste-verba-reducao-massiva-form-linhas>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn id="voltar" @click="abrirModalConfirmacao('voltar')">
          {{ $t('label.voltar') }}
        </v-btn>
        <v-btn id="concluir" @click="abrirModalConfirmacao('concluir')"
          color="red"
          :dark="exibeSpreadsheet"
          v-if="!somenteLeitura && exibeConcluir"
          v-show="informacaoFluxoCarregada"
          :disabled="!exibeSpreadsheet">
          {{ $t('label.concluir') }}
        </v-btn>
        <v-btn @click="abrirModalConfirmacao('solicitarAprovacao')"
          color="red"
          :dark="solicitanteFluxo && exibeSpreadsheet"
          v-if="!somenteLeitura && exibeSolicitarAprovacao"
          :disabled="!solicitanteFluxo || !exibeSpreadsheet">
          {{ $t('label.solicitar_aprovacao') }}
        </v-btn>
        <v-btn @click="abrirJustificativaAprovar()"
          dark
          color="primary"
          v-if="ajusteEmAprovacao"
          v-show="aprovadorAtual">
          {{ $t('label.aprovar') }}
        </v-btn>
        <v-btn @click="abrirJustificativaReprovar()"
          dark
          color="red"
          v-if="ajusteEmAprovacao"
          v-show="aprovadorAtual">
          {{ $t('label.reprovar') }}
        </v-btn>
        <v-btn @click="abrirJustificativaAnalise()"
          dark
          color="primary"
          v-if="ajusteEmAprovacao"
          v-show="aprovadorAtual">
          {{ $tc('label.enviar_analise', 1) }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <ajuste-verba-reducao-massiva-validacao
      ref="validacao"
      :get-celula-entidade="(e) => this.$refs.linhas.getCelulaEntidade(e)"
      />
    <confirm
      ref="modalConfirmacao"
      :message="mensagemConfirmacao"
      @agree="executarAcao"
    />
    <ajuste-verba-reducao-massiva-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :observacao="justificativa"
      :somenteLeitura="justificativaSomenteLeitura"
    />
  </div>
</template>
<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';

import AjusteVerbaReducaoMassivaFormResumo from './AjusteVerbaReducaoMassivaFormResumo';
import AjusteVerbaReducaoMassivaFormLinhas from './AjusteVerbaReducaoMassivaFormLinhas';
import AjusteVerbaReducaoMassivaJustificativa from './AjusteVerbaReducaoMassivaJustificativa';
import AjusteVerbaReducaoMassivaValidacao from './AjusteVerbaReducaoMassivaValidacao';
import AjusteVerbaReducaoMassivaFluxo from './AjusteVerbaReducaoMassivaFormFluxo';
import OrcamentoConfiguracao from '../../orcamento/OrcamentoConfiguracao';

export default {
  name: 'AjusteVerbaReducaoMassivaForm',
  components: {
    AjusteVerbaReducaoMassivaFormResumo,
    AjusteVerbaReducaoMassivaFormLinhas,
    AjusteVerbaReducaoMassivaJustificativa,
    AjusteVerbaReducaoMassivaValidacao,
    Confirm,
    OrcamentoConfiguracao,
  },
  mixins: [
    AjusteVerbaReducaoMassivaFluxo,
  ],
  props: {
    somenteLeitura: Boolean,
    edicao: Boolean,
    novo: Boolean,
  },
  computed: {
    ...generateComputed('AJUSTE_VERBA', [
      'canAccessPage',
    ]),
    ajusteCarregado() {
      return !!this.ajusteVerba.idAjuste;
    },
    ajusteEmAprovacao() {
      return this.ajusteVerba.status === 'AGUARDANDO_APROVACAO';
    },
    exibeSpreadsheet() {
      return this.ajusteCarregado || this.periodoAplicado;
    },
    exibeSolicitarAprovacao() {
      return this.configuracaoCarregada
             && this.possuiFluxoHabilitado
             && this.ajusteVerba.status === 'EM_CADASTRO';
    },
    exibeConcluir() {
      return this.configuracaoCarregada
             && this.ajusteVerba.status === 'EM_CADASTRO'
             && !this.possuiFluxoHabilitado;
    },
    possuiFluxoHabilitado() {
      return this.configuracao
             && this.configuracao.fluxoHabilitado;
    },
  },
  data() {
    return {
      resource: this.$api.ajusteVerbaReducaoMassiva(this.$resource),
      workflowAjusteVerbaResource: this.$api.workflowAjusteVerba(this.$resource),
      ajusteVerbaResource: this.$api.ajusteVerba(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),
      configuracao: null,
      configuracaoCarregada: false,
      formValido: false,
      mensagemConfirmacao: null,
      acaoConfirmacao: null,

      periodoAplicado: false,
      informacaoFluxoCarregada: false,

      ajusteVerba: {
        tipoVerba: null,
        orcamento: null,
        periodoOrcamentario: null,
        justificativa: null,
        status: 'EM_CADASTRO',
        linhas: [],
      },
    };
  },
  methods: {
    aplicarPeriodo(ajusteVerba) {
      const { tipoVerba, orcamento, periodoOrcamentario } = ajusteVerba;
      const params = {
        idTipoVerba: tipoVerba.id,
        idOrcamento: orcamento.id,
        idPeriodo: periodoOrcamentario.id,
      };
      this.ajusteVerba = ajusteVerba;

      this.resource.buscarLinhasOrcamento(params)
        .then((response) => {
          this.ajusteVerba.linhas = response.data;

          if (this.periodoAplicado) {
            this.$refs.linhas.refazerSpreadsheet();
          } else {
            this.periodoAplicado = true;
          }
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    carregarAjusteVerba(idAjuste) {
      this.resource.carregar({ idAjuste }).then((res) => {
        this.ajusteVerba = res.data;
        if (this.ajusteVerba.status === 'EM_CADASTRO') {
          this.carregarConfiguracao(this.ajusteVerba.tipoVerba);
        }
        this.verificarUsuarioAprovador();
      })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    abrirModalConfirmacao(acao) {
      if (acao === 'voltar') {
        this.mensagemConfirmacao = this.$t('message.deseja_voltar');
        this.acaoConfirmacao = 'voltar';
      } else if (acao === 'concluir') {
        this.mensagemConfirmacao = this.$t('message.deseja_concluir', { text: this.$tc('label.ajuste_verba', 1).toLowerCase() });
        this.acaoConfirmacao = 'concluir';
      } else if (acao === 'solicitarAprovacao') {
        this.mensagemConfirmacao = this.$t('message.deseja_solicitar_aprovacao_ajuste_verba');
        this.acaoConfirmacao = 'solicitarAprovacao';
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    },
    executarAcao() {
      if (this.acaoConfirmacao === 'voltar') {
        this.voltar();
      } else if (this.acaoConfirmacao === 'concluir') {
        this.concluir();
      } else if (this.acaoConfirmacao === 'solicitarAprovacao') {
        this.solicitarAprovacao();
      }
    },
    voltar() {
      this.$router.push({
        name: this.$route.params.from || 'ajuste-verba',
      });
    },
    concluir() {
      if (!this.$refs.resumo.valida()) return;
      if (!this.$refs.linhas.valida()) return;

      const parametros = {
        ...this.ajusteVerba,
        linhas: this.$refs.linhas.linhasValidas(),
      };

      const promise = !this.ajusteVerba.idAjuste
        ? this.resource.salvar(parametros)
        : this.resource.atualizar(parametros);

      promise
        .then((res) => {
          const idAjuste = res.data;
          this.workflowAjusteVerbaResource.existeFluxoCadastrado({ idAjuste })
            .then((resp) => {
              const existeFluxo = resp.data;
              return this.resource.finalizar({ idAjuste }, existeFluxo);
            })
            .then(() => {
              this.$toast(this.$t('message.adicionado_confira_tabela'));
              this.voltar();
            })
            .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
        })
        .catch((err) => this.$refs.validacao.apresentaErrosServidor(err));
    },
    accessForbidden() {
      if (!this.canAccessPage) {
        this.$router.push({ name: 'inicio' });
        this.$toast(this.$t('message.acesso_negado'));
      }
    },
    carregarSeUsuarioPossuirAcesso(idAjuste) {
      this.ajusteVerbaResource.verificarSeUsuarioPossuiAcesso({ idAjuste })
        .then((res) => {
          if (res.data) {
            this.carregarAjusteVerba(idAjuste);
          } else {
            this.$router.push({ name: 'inicio' });
            this.$toast('Acesso negado');
          }
        }).catch((err) => {
          this.$toast(err);
        });
    },
    orcamentoAlterado(tipoVerba = null) {
      this.solicitanteFluxo = false;
      this.periodoAplicado = false;
      this.configuracaoCarregada = false;

      if (tipoVerba === null) return;
      this.carregarConfiguracao(tipoVerba);
    },
    carregarConfiguracao(tipoVerba) {
      const idTipoVerba = tipoVerba.id;

      const promise = this.configuracaoResource.buscarConfigVigente({ idTipoVerba });

      promise.then((res) => {
        this.configuracao = new OrcamentoConfiguracao(res.data);
        this.configuracaoCarregada = true;
        this.verificarUsuarioSolicitante();
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => this.accessForbidden(), 1E3);
      return;
    }

    if (!this.novo) {
      const { id, from } = this.$route.params;
      if (!from) {
        this.carregarSeUsuarioPossuirAcesso(id);
      } else {
        this.carregarAjusteVerba(id);
      }
    }
  },
};
</script>
